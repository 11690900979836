.App {
  text-align: center;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-header {
  font-size: 2.5vw;
  color: black;
  padding-top: 3%;
  padding-bottom: 3%;
}

.App-title {
  border-radius: 40%;
  border-style: solid;
}

form {
  width: 75%;
}

.App-search {
  text-align: center;
  width: 40%;
  font-size: 2vw;
  border-style: solid;
  border-color: black;
}

.App-search:focus {
  outline:none;
}

button {
  font-size: 2vw;
}

button:focus {
  outline:none;
}

.App-result {
  padding-top: 2%;
  font-size: 1.2vw;
  width: 45%;
  white-space: pre-wrap;
}

.App-disclaimer {
  font-size: 1.2vw;
  width: 40%;
}

.App-contactme {
  font-size: 1.4vw;
}
